import React from 'react';

const About = props => {
  return(
    <div className="aboutDetails">
      <h1>About</h1>
      <p><em>Bullet Points Monthly</em> features long-form articles and podcasts that look to place games in a wider cultural context, examining their relationship to our greater world much in the same manner as other forms of art and entertainment.</p>
      <p>Our editorial staff consists of Reid McCarter (<em>The A.V Club, GQ, Kill Screen</em>) and Yussef Cole (<em>Unwinnable, Waypoint, Paste</em>). Previously, editors included Ed Smith, Astrid Rose, and Jess Joho. Bullet Points Monthly aims to look at videogames (particularly new, mainstream releases) through exploratory criticism, using a monthly format to carefully consider the most fascinating and important aspects of contemporary games.</p>
      <p>Bullet Points is proud to be part of SUPERCULTURE, a network of podcasts and websites dedicated to the underexplored. Tweet us <a href="https://twitter.com/SupercultureNet">@superculturenet</a>.</p>
      <p>For inquiries, comments, or questions, please email <a href = "mailto: contact@bulletpointsmonthly.com">contact@bulletpointsmonthly.com</a>. We can also be reached through Twitter <a href="https://twitter.com/BulletPointsVG">@BulletPointsVG</a>.</p>
      <p>Website designed and developed by <a href="https://dwfries.com">Daniel Fries</a>.</p>
      <p>© 2023 Bullet Points Monthly</p>
</div>
    )
}

export default About
